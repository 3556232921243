import PropTypes from "prop-types"
import React from "react"
import { Link } from 'gatsby'
import Icon from './icon'
import useTranslations from '../components/useTranslations'
import { LocaleContext } from '../components/layout'

import InstagramFeed from './instagram-feed-2'

const Footer = ({ siteTitle }) => {
  const { locale } = React.useContext(LocaleContext)
  const {
    lunes,
    martes,
    viernes,
    sabado } = useTranslations()
  return (
    <footer id="colophon" className="footer site-footer">
      <div className="footer__container">

        <div className="gallery gallery--columns-3">
          <div className="gallery__item">
            <p>C/ del Rosselló, 256, 1º 2ª<br/>Barcelona, 08037</p>
          </div>
          <div className="gallery__item">
            <p><a href="tel:+34933824154">(+34) 93 382 41 54</a>
            <br/>{lunes}: 12-20h,
            <br/>{martes} - {viernes}: 11-20h,
            <br/>{sabado}: cita previa</p>
          </div>
          <div className="gallery__item">
            <p>barcelona@paola-carolina.com<br/>www.paola-carolina.com</p>
          </div>
        </div>

        <div className="footer__bottom">
          <h3>{locale === 'es' ? 'Síguenos' : 'Follow us'} &nbsp;&#8212;</h3>
          <span style={{display:'block',marginBottom:'2rem'}}>
            <a className="button button--transparent" href="https://www.facebook.com/paolacarolinabarcelona"><Icon name="facebook-f"/><span> Facebook</span></a>
            <a className="button button--transparent" href="https://www.instagram.com/paolacarolina_bcn/"><Icon name="instagram"/><span> Instagram</span></a>
            <a className="button button--transparent" href="https://www.tiktok.com/@paolacarolina_bcn"><Icon name="tiktok"/><span> TikTok</span></a>
          </span>
          <Link to={`${locale === 'es' ? '/en' : '/'}`} className="button">{locale === 'es' ? 'English versión' : 'Versión en español'}</Link>
          <p>Made with <span role="img" aria-label="love">💚</span> by clicksound</p>
        </div>

      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
