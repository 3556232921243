import React, { useState } from "react"

import useTranslations from "./useTranslations"
import Link from "./localizedLink"

const Menu = () => {
  const { menu } = useTranslations()
  const [ navigation, toggle ] = useState(false)
  return (
  <nav className={`main-navigation ${navigation ? 'main-navigation toggled' : null}`}>
    <button 
      className="menu-toggle link"
      style={{padding: '0'}}
      onClick={() => toggle(!navigation)}
    >
      menu &#8212;&#8212;&nbsp;
    </button>
    <ul>
      {menu.items.map(item => (
        <li key={item.name}>
          <Link
            to={`/${item.slug}`}
            activeClassName="active"
            onClick={() => toggle(!navigation)}
          >
            {item.name}
          </Link>
        </li>
      ))}
        <li>
          <a href="https://blog.paola-carolina.com">Blog</a>
        </li>
    </ul>
  </nav>
  )
}

export default Menu
