module.exports = {
  es: {
    default: true,
    path: `es`,
    locale: `es-ES`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `es`,
    ogLanguage: `es_ES`,
    defaultTitle: `Atelier Paola Carolina | Vestidos de novia artesanales‎`,
    defaultDescription: `Vestidos para una novia divertida, natural, femenina y con un toque de rock&roll. Hechos a mano en Barcelona. Slowfashion nupcial. Proveedores locales.`,
  },
  en: {
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Atelier Paola Carolina | Handmade wedding dresses`,
    defaultDescription: `Made with Love and magic in Barcelona for rebel brides. Slowfashion Bridal. Local suppliers.`,
  },
}