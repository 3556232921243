import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Masonry from 'react-masonry-css'

const StyledImage = styled.img`
  border-radius: .5em;
  overflow: hidden;
`

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

const InstagramFeed = () => {
  const [ posts, setPosts ] = useState(null)
  useEffect(() => {
    fetch(`https://www.instagram.com/graphql/query/?query_hash=7437567ae0de0773fd96545592359a6b&variables={%22id%22:%224983497528%22,%22first%22:8}`)
      .then( response => response.json())
      .then(resultData => {
        setPosts(slimUpPosts(resultData))
      })
  }, [])
  const slimUpPosts = (response) => 
    response.data.user.edge_owner_to_timeline_media.edges.map(
      edge => ({
        thumbnail: edge.node.thumbnail_src,
        url: `https://instagram.com/p/${edge.node.shortcode}`,
        id: edge.node.id,
        caption: edge.node.edge_media_to_caption.edges[0].node.text,
      })
    )
  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {posts && posts.map((post) => (
        <div key={post.id}>
          <a href={post.url}>
            <StyledImage key={post.id} src={post.thumbnail} alt={post.caption} />
          </a>
        </div>
      ))}
    </Masonry>
  )
}

export default InstagramFeed