import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

const Icon = ({ lib, name }) => (
  <FontAwesomeIcon icon={[lib, name ]} />
)

Icon.propTypes = {
  name: PropTypes.string,
  lib: PropTypes.string
}

Icon.defaultProps = {
  name: ``,
  lib: `fab`
}

export default Icon