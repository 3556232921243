import React from "react"
import { Layout } from "./src/components/layout"
import posed, { PoseGroup } from 'react-pose'

const Transition = posed.div({
  enter: { opacity: 1, delay: 500 },
  exit: { opacity: 0  }
})

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement = ({ element, props}) => (
  <Layout {...props}>
     <PoseGroup style={{ display: 'table', width: '100%' }} flipMove={false}>
        <Transition key={props.location.pathname}>
          {element}
        </Transition>
      </PoseGroup>
  </Layout>
)

export default wrapPageElement