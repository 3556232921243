import React, { createContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from './footer'
// import "./layout.css"

import "../styles/main.scss"

const LocaleContext = createContext()

const Layout = ({ children, pageContext: { locale }, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <LocaleContext.Provider value={{ locale, location }}>
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <Footer/>
    </LocaleContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout, LocaleContext }
