import PropTypes from "prop-types"
import React from 'react'

import Menu from './menu'
import Link from './localizedLink'
import logo from '../images/logo.svg'

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header__container">
      <div className="branding">
        <Link to="/">
          <img src={logo} alt={`Logo ${siteTitle}`} />
        </Link>
      </div>
      <Menu/>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
